import React from 'react';
import { User } from 'firebase';
import { Link } from 'react-router-dom';
import PromiseLoader from '../../templates/PromiseLoader';
import DefaultProps from '../../../models/default_props';
import Theming from '../../../constants/theming';
import { withAppContext } from '../../app-context';
import MembershipService from '../../../services/membership_service';
import RoutePath from '../../../constants/route-paths';
import LoadingButton from '../../templates/LoadingButton';
import { Membership as MembershipModel } from '../../../models/membership';
import { MembershipModal } from './modal';

interface InterfaceProps extends DefaultProps {
    isChecked: Boolean,
    isAuthenticated: () => User | null
}

interface InterfaceState {
    modalShow: Boolean,
    refresh: Boolean
}

class Membership extends PromiseLoader<MembershipModel[], InterfaceProps, InterfaceState> {
    async promise() {
        return MembershipService.getMembershipStatus()
    }
    
    constructor(props: InterfaceProps) {
        super(props);
        this.state = { modalShow: false, refresh: false };
    }

    handleClick = () => {
        this.toggleModal(true);
    }

    handleCancel = async (id: String) => {
        await MembershipService.cancelMembership(id);
        this.setState({ refresh: !this.state.refresh })
    }

    handleFormSubmit = async (transactionID: String) => {
        await MembershipService.applyMembership(transactionID, this.props.isAuthenticated()?.email as String)
        this.toggleModal(false);
        this.setState({ refresh: !this.state.refresh })
    }
    
    toggleModal = (toggle: boolean) => {
        this.setState({
          modalShow: toggle
        });
    }

    render() {
        return(
            <main className="container my-5 py-5">
                <div className="container" style={{ color: Theming.textColourDark }}>
                    <h2 className="h2-responsive"><span style={{ color: Theming.backgroundColourDark }}>SHAM</span> Membership</h2>
                    <hr className="my-3"></hr>

                    <div className="jumbotron">
                        <h3 className="display-4">Membership @ SHAM</h3>
                        <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <hr className="my-4" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        {super.render()}
                    </div>
                    <MembershipModal show={this.state.modalShow} onHide={() => this.toggleModal(false)} onFormSubmit={this.handleFormSubmit} />
                </div>
            </main>
        )
    }

    loadingComponent() {
		return (
			<div className="justify-content-center">
				<div className="spinner-grow text-dark" role="status" style={{ backgroundColor: Theming.backgroundColourDark }}>
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

    dataComponent(membershipJson: MembershipModel[]) {
        const pending: MembershipModel[] = [], approved: MembershipModel[] = [];
        membershipJson.forEach((item) => {
            switch(item.status.toLowerCase()){
                case 'pending':
                    pending.push(item);
                    break;
                case 'approved':
                    approved.push(item);
            }
        })
        if(approved.length >0 ) {
            return(
                <p>You are already a member!</p>
            );
        }
        else if(pending.length === 0){
            return (
                <button className="btn" onClick={this.handleClick} style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark, backgroundColor: Theming.backgroundColourDark }}>Buy Membership</button>
            )
        } else {
            return (
                <table className="table">
                    <thead className="white-text" style={{ backgroundColor: Theming.backgroundColourDark }}>
                        <tr>
                            <th scope="col">Email</th>
                            <th scope="col">Payment ID</th>
                            <th scope="col">Status</th>
                            <th scope="col">Cancel Request</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{pending[0].email}</td>
                            <td>{pending[0].paymentID}</td>
                            <td>{pending[0].status}</td>
                            <td><LoadingButton onClick={() => this.handleCancel(pending[0].id)}>Cancel</LoadingButton></td>
                        </tr>
                    </tbody>
                </table>
            )
        }
    }

    errorComponent(_error: any) {
        return(
            <Link className="btn" to={RoutePath.login} style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark, backgroundColor: Theming.backgroundColourDark }}>Login to Buy Membership</Link>
        )
    }
}

export default withAppContext(Membership);