import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Theming from '../../../constants/theming';
import LoadingButton from '../../templates/LoadingButton';

interface FnInterfaceProps {
    show: Boolean,
    onHide: () => void,
    onFormSubmit: (transactionId: String) => Promise<void>,
}

export function MembershipModal(props: FnInterfaceProps) {
    const [isChecked, setChecked] = useState(false);
    const [transactionId, setTransactionId] = useState("");

    const handleChange = () => {
        setChecked(!isChecked)
    }

    const handleFormSubmit = () => {
        return props.onFormSubmit(transactionId);
    }

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-uppercase" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Membership Form
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form>
                <Tabs defaultActiveKey="BankTransfer" id="uncontrolled-tab-example">
                    <Tab eventKey="BankTransfer" title="Bank Transfer">
                        <div className="container py-3 text-center">
                            <div>
                                <p>Account No: 789654123478965</p>
                                <p>IFSC Code: 879456321</p>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="UPI" title="UPI">
                        <div className="container py-3 text-center">
                            <div>
                                <img src="https://static.vikaspedia.in/media/images_en/e-governance/digital-payment/unified-payment-interface/Untitled.png" alt="UPICode"/>
                                <p>Scan the Above UPI code to pay</p>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
                <hr />
                <div className="md-form">
                    <input type="text" id="transactionID" className="form-control" value={transactionId} onChange={(event) => {setTransactionId(event.target.value)}} required={true}/>
                    <label htmlFor="transactionID">Transaction ID</label>
                </div>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" checked={isChecked} onChange={handleChange} className="custom-control-input" id="checkedPaid" />
                    <label className="custom-control-label" htmlFor="checkedPaid">I have paid the amount</label>
                </div>
                <div className="md-form">
                    <LoadingButton onClick={handleFormSubmit} disabled={!isChecked}>Submit</LoadingButton>
                </div>
            </form>
        </Modal.Body>
      </Modal>
    );
}